<template>
  <span class="no-found-records">~~~ {{ msg }} ~~~ </span>
</template>

<script>
export default {
  props: {
    msg: { type: String, default: '未发现记录' }
  }
}
</script>

<style scoped>
  .no-found-records{
    display:block;
    padding: calc(var(--mp) * 2);
    text-align: center;
    color:var(--color-text-sub);
    font-size: .875rem;
  }
</style>
