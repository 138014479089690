<template>
  <div class="page-ctx">
    <BkCalendar
      :stats="dateStats"
      :st-date="$moment().add(1,'d').format('YYYY-MM-DD')"
      :ed-date="$moment().add(booking_within_days,'d').format('YYYY-MM-DD')"
      @selectDay="onSelectDay"
      @monthChange="onMonthChange"
    />

    <van-cell-group title="选择时段">
      <div class="bk-times">
        <div v-for="item in timeStats" v-show="item.isWorkTime" :key="item.time" :class="{'bk-time':true, selected:fmData.time===item.time}" @click="onSelectTime(item)">
          <span>{{ item.time }}</span>
          <div class="progress"><span :style="{width:item.percent+'%'}" /></div>
        </div>
      </div>
      <NotFoundRecords v-if="timeStats.length===0" msg="未发现可用时段!" />
    </van-cell-group>

    <van-cell-group title="预约信息">
      <van-field :value="$route.query.plateNo" readonly input-align="right" label="号牌" />
      <van-field :value="user.name" readonly input-align="right" label="预约人" />
      <van-field :value="user.phone" readonly input-align="right" label="手机" />
      <van-field :value="bookingTime" readonly input-align="right" label="预约时间" />
    </van-cell-group>

    <div class="btns-col" style="padding:var(--mp);">
      <van-button type="primary" :disabled="!bookingTime" @click="doBooking">
        {{(isPayEnable && inWhiteList(user.phone))?'支 付':'预 约'}}
      </van-button>
      <van-button icon="arrow-left" @click="$router.replace('/cust-vl/mng')"> 返 回</van-button>
    </div>
  </div>
</template>

<script>
import BkCalendar from '@/components/BkCalendar'
import NotFoundRecords from '@/components/NotFoundRecords'
import BKApi from '@/apis/BKApi'
import WxPayApi from '@/apis/WxPayApi'
import { mapGetters, mapState } from 'vuex'

export default {
  components: { BkCalendar, NotFoundRecords },
  data() {
    return {
      month: this.$moment().format('YYYY-MM'),
      date: this.$moment().format('YYYY-MM-DD'),
      dateStats: [],
      timeStats: [],
      fmData: {
        vlId: '',
        date: '',
        time: ''
      },
      wxAuthUrl: 'abc'
    }
  },
  computed: {
    bookingTime: function() {
      if (!this.fmData.date || !this.fmData.time) return ''
      return this.fmData.date + ' ' + this.fmData.time
    },
    ...mapGetters({
      'user': 'user',
      'isPayEnable': 'wxpay/isPayEnable',
      'inWhiteList': 'wxpay/inWhiteList'
    }),
    ...mapState({
      booking_within_days: state => state.env.booking_within_days
    })
  },
  created() {
    this.fmData.vlId = this.$route.query.id
    this.onMonthChange(this.month)
  },
  methods: {
    doBooking() {
      BKApi.custAddBK(this.fmData).then(rsp => {
        if (this.isPayEnable && this.inWhiteList(this.user.phone)) {
          this.wxAuthUrl = WxPayApi.getWxAuthUrl(
            this.$store.state.wxpay.wxAuthUrl
            , this.$store.state.wxpay.appId
            , this.$store.state.wxpay.entryUrl
            , rsp.data.id)
          location.replace(this.wxAuthUrl)
        } else {
          this.$router.replace('/cust-bk/mng')
        }
      })
    },
    onSelectTime(ti) {
      if (ti.total < ti.max) {
        this.fmData.time = ti.time
      }
    },
    onMonthChange(month) {
      this.month = month
      this.date = ''
      this.fmData.date = ''
      this.fmData.time = ''
      this.timeStats = []
      BKApi.countByDate(this.month).then(rsp => {
        this.dateStats = rsp.data
      })
    },
    onSelectDay(dt) {
      this.date = dt
      this.fmData.date = dt
      this.fmData.time = ''
      BKApi.countByTime(this.date).then(rsp => {
        this.timeStats = rsp.data
      })
    }
  }
}
</script>

<style scoped>
.bk-times{
  display: flex;
  flex-wrap: wrap;
  background-color: var(--color-bg);
  font-size: .875rem;
}
.bk-time{
  width: calc((100vw - 4px) / 4);
  padding: 5px 0px;
  margin-bottom: 1px;
  margin-right: 1px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.bk-time > span{
  margin-bottom: 5px;
}
.selected {
  background-color: var(--color-primary);
  color: white;
}
</style>
