import axios from '@/plugins/request'

export default {
  query(kw, filter, sort, pageNum, pageSize) {
    return axios({
      url: '/BookingRecords/',
      method: 'GET',
      params: {
        kw: kw,
        filter: encodeURI(JSON.stringify(filter)),
        sort: encodeURI(JSON.stringify(sort)),
        pageNum: pageNum,
        pageSize: pageSize
      }
    })
  },

  getById(id) {
    return axios({
      url: '/BookingRecords/' + id,
      method: 'GET'
    })
  },

  changeStatus(id, ov, nv) {
    return axios({
      url: '/BookingRecords/changeStatus',
      method: 'PUT',
      params: {
        id: id,
        ov: ov,
        nv: nv
      }
    })
  },

  // customer actions================
  getCustBKs() {
    return axios({
      url: '/BookingRecords/custBKs',
      method: 'GET'
    })
  },
  getCustBkById(id) {
    return axios({
      url: '/BookingRecords/custBKs/' + id,
      method: 'GET'
    })
  },
  custAddBK(bk) {
    return axios({
      url: '/BookingRecords/custAddBK',
      method: 'POST',
      data: bk
    })
  },
  custCancelBK(bkId) {
    return axios({
      url: '/BookingRecords/custCancelBK',
      method: 'PUT',
      params: { id: bkId }
    })
  },

  // stats ======================
  countByDate(month) {
    return axios({
      url: '/BookingRecords/calendar/countByDate',
      method: 'GET',
      params: { month: month }
    })
  },

  countByTime(dt) {
    return axios({
      url: '/BookingRecords/calendar/countByTime',
      method: 'GET',
      params: { date: dt }
    })
  }
}
