<template>
  <div class="calendar">
    <div class="title-month">
      <van-icon name="arrow-left" @click="preMonth" />
      <span>{{ monthDay.format('YYYY年MM月') }}</span>
      <van-icon name="arrow" @click="nextMonth" />
    </div>
    <div class="title-weekday">
      <span v-for="item in weekdays" :key="item">{{ item }}</span>
    </div>
    <div class="days-wrap">
      <div
        v-for="(day,idx) in days"
        :key="idx"
        :class="{disabled:day.disabled, selected:isSelected(day.date), 'day-ctx':true}"
        @click="onSelect(day)"
      >
        <span>{{ $moment(day.date).date() }}</span>
        <div class="progress">
          <span :style="{width: day.percent+'%'}" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    stats: { type: Array, default: () => { return [] } },
    holidays: { type: Array, default: () => { return [] } },
    stDate: { type: String, default: '' },
    edDate: { type: String, default: '' }
  },
  data() {
    return {
      weekdays: ['一', '二', '三', '四', '五', '六', '日'],
      monthDay: this.$moment().date(1),
      today: this.$moment(),
      selectedDay: undefined,
      days: []
    }
  },
  watch: {
    stats: function(nv, ov) {
      this.setStats()
    }
  },
  created() {
    this.init()
    this.setStats()
  },
  methods: {
    setStats() {
      const that = this
      that.days.forEach(day => {
        that.stats.forEach(item => {
          if (day.date === item.date) {
            day.percent = item.percent
            day.disabled = !(item.isWorkTime && this.inDateRange(day.date))
          }
        })
      })
    },
    init() {
      const days = []
      const st = this.$moment(this.monthDay).date(1)
      let dw = st.weekday()
      dw = dw === 0 ? 7 : dw
      for (let i = dw - 1; i > 0; i--) {
        days.push({
          date: this.$moment(st).subtract(i, 'days').format('YYYY-MM-DD'),
          disabled: true,
          percent: 0
        })
      }

      for (let i = 0; i < st.daysInMonth(); i++) {
        const dt = this.$moment(st).add(i, 'days').format('YYYY-MM-DD')
        days.push({
          date: dt,
          disabled: true,
          percent: 0
        })
      }

      dw = days.length % 7
      if (dw > 0) {
        for (let i = 0; i < (7 - dw); i++) {
          days.push({
            date: this.$moment(st).add(st.daysInMonth() + i, 'days').format('YYYY-MM-DD'),
            disabled: true,
            percent: 0
          })
        }
      }
      this.days = days
    },
    preMonth() {
      if (this.$moment(this.monthDay).add(-1, 'd').isBefore(this.stDate)) return

      this.monthDay = this.monthDay.add(-1, 'months')
      this.selectedDay = undefined
      this.init()
      this.$emit('monthChange', this.monthDay.format('YYYY-MM'))
    },
    nextMonth() {
      if (this.$moment(this.monthDay).add(1, 'M').isAfter(this.edDate)) return

      this.monthDay = this.monthDay.add(1, 'months')
      this.selectedDay = undefined
      this.init()
      this.$emit('monthChange', this.monthDay.format('YYYY-MM'))
    },
    isSelected(date) {
      return this.selectedDay === date
    },
    onSelect(day) {
      if (!day.disabled) {
        this.selectedDay = day.date
        this.$emit('selectDay', this.selectedDay)
      }
    },
    inDateRange(dt) {
      if (!this.stDate && !this.edDate) {
        return false
      } else {
        return this.$moment(dt).isBetween(this.stDate, this.edDate, null, '[]')
      }
    }
  }
}
</script>

<style scoped>
.calendar{
    background-color: var(--color-border);
}
.title-month{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    margin-bottom: 1px;
}
.title-month > i{
  padding: 10px;
}

.title-weekday{
    display: flex;
    justify-content: space-around;
    margin-bottom: 1px;
}
.title-weekday > span{
    display: block;
    padding: 5px 0px;
    width: calc((100vw - 6px) / 7);
    text-align: center;
    background-color: white;
}

.days-wrap{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.day-ctx{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: calc((100vw - 6px) / 7);
    height: calc((100vw - 6px) / 7 * 0.618);
    background-color: white;
    margin-bottom: 1px;
}

.progress{
    width: calc((100vw - 6px) / 7 * 0.618);
    height: 4px;
    background-color: var(--color-success);
}
.progress > span{
  display: block;
  height: 4px;
  background-color: var(--color-error);
}

.disabled {
  background-color: var(--color-bg);
  color: var(--color-text-sub);
}
.disabled .progress, .disabled .progress > span{
  background-color: var(--color-bg);
}

.selected{
  background-color: var(--color-primary);
  color: white;
  font-weight: bold;
}
</style>
